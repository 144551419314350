import React, { useState, useEffect, useRef } from "react"
import { Container } from "./styledComponents"
import styled from "styled-components"
import ProjectsSection from "./projectsSection"

const Projects = styled.div`
  position: relative;
  min-height: ${(props) => props.activeCategoryHeight}px;
  overflow: hidden;
  margin-bottom: 4rem;
  .category {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: transform 0.1s ease-in;
    transform: translateY(-250%);
  }
  .category.active {
    transform: translateY(-50%);
  }
`

const Categories = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  @media (max-width: 1195px) {
    justify-content: center;
    flex-wrap: wrap;
  }
  .category {
    border: none;
    background: none;
    cursor: pointer;
    margin: 2rem;
    color: ${(props) => props.theme.primary};
    font: 700 1.5rem/1.8125rem "Montserrat";
    @media (max-width: 1195px) {
      margin: 0.5rem 2rem;
    }
    @media (max-width: 768px) {
      margin: 0.5rem;
    }
    :focus {
      outline: none;
    }
  }
  .category.active {
    text-decoration: line-through ${(props) => props.theme.secondary};
    text-decoration-thickness: 1px;
  }
`

const ProjectsWithCategories = ({ projects, projectsTaxonomy }) => {
  const activeCategoryRef = useRef()
  const [activeCategory, setActiveCategory] = useState(
    projectsTaxonomy[0].wordpress_id
  )
  const [activeCategoryHeight, setActiveCategoryHeight] = useState(650)
  useEffect(() => {
    setActiveCategoryHeight(activeCategoryRef.current.clientHeight)
  }, [activeCategory])
  const projectsObject = {}
  for (const project of projects) {
    for (const taxonomy of project.project_taxonomy) {
      const projectToAdd = {
        title: project.title,
        featured_media: project.featured_media,
      }
      if (Array.isArray(projectsObject[taxonomy])) {
        projectsObject[taxonomy].push(projectToAdd)
      } else {
        projectsObject[taxonomy] = [projectToAdd]
      }
    }
  }
  console.log(projectsTaxonomy)
  return (
    <Container>
      <Categories>
        {projectsTaxonomy.length > 0 &&
          projectsTaxonomy.map((taxonomy) => (
            <button
              aria-label={`Show ${taxonomy.name} category`}
              type="button"
              key={taxonomy.wordpress_id}
              onClick={() => setActiveCategory(taxonomy.wordpress_id)}
              className={
                taxonomy.wordpress_id === activeCategory
                  ? "active category"
                  : "category"
              }
            >
              {taxonomy.name}
            </button>
          ))}
      </Categories>
      <Projects activeCategoryHeight={activeCategoryHeight}>
        {projectsTaxonomy.length > 0 &&
          projectsTaxonomy.map((taxonomy) => (
            <div
              ref={
                taxonomy.wordpress_id === activeCategory
                  ? activeCategoryRef
                  : null
              }
              key={taxonomy.wordpress_id}
              className={
                taxonomy.wordpress_id === activeCategory
                  ? "active category"
                  : "category"
              }
            >
              <ProjectsSection categoryID={taxonomy.wordpress_id} />
            </div>
          ))}
      </Projects>
    </Container>
  )
}

export default ProjectsWithCategories
