import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled, { css } from "styled-components"
import BaseImg from "./baseImg"
import { useSwiper } from "./hooks/useSwiper"
import { BaseButton } from "./styledComponents"
import { MediumMobileFont } from "./typography"
import SliderControls from "./sliders/sliderControls"
import { useAnimatedBorder } from "../helpers"

const Slide = styled.li`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  @media screen and (max-width: 1195px) {
    flex-direction: column;
  }
  @media screen and (max-width: 768px) {
    margin-top: 70px;
  }
`

const Slider = styled.div`
  width: 100%;
  display: flex;
`

const SlideContent = styled.div`
  max-width: 440px;
  padding: 2.25rem 2rem;
  position: relative;
  border: 1px solid ${(props) => props.theme.secondary};
  border-top: none;
  border-bottom: none;
  @media screen and (max-width: 1195px) {
    text-align: center;
  }
`

const Border = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 33%;
  left: 0;
  ${(props) =>
    props.side === "right" &&
    css`
      left: auto;
      right: 0;
    `}
  ::before,
  ::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    border-top: 1px solid ${(props) => props.theme.secondary};
    ${(props) =>
      props.side === "right" &&
      css`
        left: auto;
        right: 0;
        border-top: none;
        border-bottom: 1px solid ${(props) => props.theme.secondary};
      `}
    width: 100%;
    transition: transform 1s ease-out;
  }
  ::before {
    top: 0;
    transform: scaleX(calc(1 + var(--scale)));
    transform-origin: left;
    ${(props) =>
      props.side === "right" &&
      css`
        transform: scaleX(calc(1 - var(--scale)));
        transform-origin: right;
      `}
  }
  ::after {
    bottom: 0;
    transform: scaleX(calc(1 - var(--scale)));
    transform-origin: left;
    ${(props) =>
      props.side === "right" &&
      css`
        transform: scaleX(calc(1 + var(--scale)));
        transform-origin: right;
      `}
  }
`

const SlideImg = styled(BaseImg)`
  width: 50%;
  margin: 0 20px 0 0;
  @media screen and (max-width: 1195px) {
    margin: 0 0 20px 0;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const SliderControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 36px 0 -50px;
  z-index: 2;
  position: relative;
  width: 100%;
  @media screen and (max-width: 768px) {
    order: 5;
    margin: 10px 0 60px;
    justify-content: center;
  }
`

const ProjectsSection = ({ categoryID }) => {
  const [boxRef] = useAnimatedBorder()
  let { allWordpressWpProject } = useStaticQuery(
    graphql`
      query {
        allWordpressWpProject {
          nodes {
            cmb2 {
              metabox_post_project {
                text
                title
                buttonText
                buttonUrl
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
            project_taxonomy
          }
        }
      }
    `
  )

  let projects = allWordpressWpProject.nodes
  if (categoryID !== undefined) {
    projects = projects.filter((project) => {
      return project.project_taxonomy.includes(categoryID)
    })
  }
  const { swiperElement, swiperInstance, activeSlideIndex } = useSwiper(
    projects,
    {
      spaceBetween: 16,
    }
  )

  return (
    <>
      <SliderControlsWrapper>
        <SliderControls
          swiperInstance={swiperInstance}
          activeSlideIndex={activeSlideIndex}
          slides={projects}
        />
      </SliderControlsWrapper>
      <Slider ref={boxRef}>
        <div className="swiper-container" ref={swiperElement}>
          <ul style={{ margin: "0" }} className="swiper-wrapper">
            {projects.map((project) => {
              const {
                text,
                title,
                buttonText,
                buttonUrl,
                image,
              } = project.cmb2.metabox_post_project
              return (
                <Slide className="swiper-slide" key={title}>
                  {image ? <SlideImg localFile={image.localFile} /> : null}
                  <SlideContent>
                    <Border side="left" />
                    <MediumMobileFont desktopFontSize="1.75rem">
                      <h3 style={{ marginBottom: "0.5em" }}>{title}</h3>
                    </MediumMobileFont>
                    <p dangerouslySetInnerHTML={{ __html: text }} />
                    {buttonText && (
                      <BaseButton to={buttonUrl} size="medium">
                        {buttonText}
                      </BaseButton>
                    )}
                    <Border side="right" />
                  </SlideContent>
                </Slide>
              )
            })}
          </ul>
        </div>
      </Slider>
    </>
  )
}

export default ProjectsSection
